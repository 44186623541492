<template>
  <PegawaiForm mode="Ubah" module="Pegawai"> </PegawaiForm>
</template>

<script>
import PegawaiForm from './form';

const PegawaiUpdate = {
  name: 'PegawaiUpdate',
  components: { PegawaiForm },
};

export default PegawaiUpdate;
</script>
